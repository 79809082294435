"use client"
import React, {FC} from "react";
import s from "./Button.module.scss"

interface IButton {
    text: string
    cb?: () => void
    theme?: boolean
    class_?: string
    width?: string
    link?: string
    styleObj?: any
}

const Button:FC<IButton> = ({text, cb= () => null, theme = true, class_, width, link, styleObj}) => {

    const style: any = styleObj || {}
    if (width) style.width = width

    return <>
        {link ? <a className={`${s.Button} ${theme ? s.theme : ''} ${class_ || ''}`} onClick={cb} href={link} style={style}>{text}</a>
            : <button className={`${s.Button} ${theme ? s.theme : ''} ${class_ || ''}`} onClick={cb} style={style}>{text}</button>}
    </>

}

export default Button