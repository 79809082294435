import {FC, useEffect} from "react";
import s from "./Step_3.module.scss"
import {ApiResponse, makeRequest} from "../../../api/api";
import {ISkillHH} from "../Step_2/Step_2";
import cn from "classnames";

interface IStep_3 {
    plan: string
    loading: boolean
}

const Step_3:FC<IStep_3> = ({plan= '', loading}) => {

    return (
        <div className={s.Step_3}>
            <div className={s.plan}>
                <svg className={cn( {[s.animation]: loading, [s.hide]: plan})} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white">
                    <path d="m668-380 152-130 120 10-176 153 52 227-102-62-46-198Zm-94-292-42-98 46-110 92 217-96-9ZM294-287l126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM173-120l65-281L20-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-340Z"/>
                </svg>
                <pre>{plan}</pre>
            </div>
        </div>
    )
}

export default Step_3