"use client"
import {FC, useEffect, useState} from "react";
import cn from "classnames";
import Step_1, {IProfession} from "./Step_1/Step_1";
import Step_3 from "./Step_3/Step_3";
import Step_2, {ISkillHH} from "./Step_2/Step_2";
import s from "./Generate.module.scss"
import {ApiResponse, makeRequest} from "../../api/api";
import Modal from "../shared/Modal/Modal";
import Button from "../shared/Button/Button";
import {links} from "../../utils/links";
// import {useAppContext} from "../../AppContext";
import useMediaQuery from "../../utils/hooks/useMediaQuery";
import {ContextType} from "../../types/globalTypes";

interface IPreviewGenerate {
    context: ContextType
    skillometerToken: string
    back3Token: string
}

type TypeGenerateData = {
    hhSkill: ISkillHH
    hhProfession: IProfession
    index: number
    id: number
    task_id: string
}

const Generate:FC<IPreviewGenerate> = ({context, skillometerToken, back3Token}) => {

    const [activeSlide, setActiveSlide] = useState(1)

    const [selectedProfession, setSelectedProfession] = useState<IProfession | null>(null)
    const [skillHH, setSkillHH] = useState<ISkillHH | null>(null)
    const [verifyId, setVerifyId] = useState<number | null>(null)
    const [taskId, setTaskId] = useState('')
    const setSelectedProfessionHandler = (profession: IProfession | null) => {
        setSelectedProfession(profession)
        setSkillHH(null)
    }
    // Переключение на другое апи
    const [backupApi, setBackupApi] = useState(false)
    // Ссылка
    // const { context } = useAppContext();
    const isMobile = useMediaQuery(800);
    const getLink = () => {
        if (context === 'Для бизнеса') return links.form
        if (context === 'Для учебных центров') return links.auth_edu
        if (context === 'Для учеников') return links.auth
    }
    // Генерация

    const [lang, setLang] = useState({selected: "sa", list: ["ru","en","sa"], active: false});
    const getLangFlag = (name: string, cb: () => void) => {
        if (name === 'ru') return <svg onClick={cb} width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="25" height="6" fill="white"/>
            <rect x="0" y="6" width="25" height="6" fill="#0F56E0"/>
           a <rect x="0" y="12" width="25" height="6" fill="#D22222"/>
        </svg>
        if (name === 'en') return <svg onClick={cb} width="25" height="18" viewBox="0 0 25 18" xmlns="http://www.w3.org/2000/svg">
            <rect width="25" height="18" fill="#00247D"/>
            <polygon points="0,0 25,18 22,18 0,2" fill="white"/>
            <polygon points="25,0 0,18 3,18 25,2" fill="white"/>
            <polygon points="0,0 25,18 21,18 0,3" fill="#CF142B"/>
            <polygon points="25,0 0,18 4,18 25,3" fill="#CF142B"/>
            <rect x="10" width="5" height="18" fill="white"/>
            <rect y="7" width="25" height="4" fill="white"/>
            <rect x="11" width="3" height="18" fill="#CF142B"/>
            <rect y="8" width="25" height="2" fill="#CF142B"/>
        </svg>
        if (name === 'sa') return <svg onClick={cb} width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="25" height="12" fill="#009DFE"/>
            <rect x="0" y="15" width="25" height="3" fill="#009B01"/>
            <rect x="0" y="13" width="25" height="2" fill="#FE0201"/>
            <rect x="0" y="11" width="25" height="2" fill="white"/>
            <circle cx="12.5" cy="5" r="3.5" fill="white"/>
        </svg>
    }
    const [plan, setPlan] = useState('')
    const [loading, setLoading] = useState(false)

    const matchIDRequest = async (skillName: string) => {
        // @ts-ignore
        const response: ApiResponse<{id: number, index: number}> = await makeRequest<{id: number, index: number}>({
            method: "POST",
            url: backupApi
                ? "https://back3-corp.skillometer.ru/api/v2/teacher/speciality/trajectory_skill/"
                : "https://skillometer.ru/api/v2/web/student/profile/trajectory_skill/",
            data: {
                index: 0,
                name: skillName
            },
            headers: {
                "authorization": backupApi ? `Token ${back3Token}` : `Token ${skillometerToken}`,
                "organization-subdomain": backupApi ? '202' : null
            }
        });
        return response.status === 201 ? response.data : {id: 0, index: 0}
    };
    const initializeGenerationRequest = async (id: number, language: string) => {
        // @ts-ignore
        const response: ApiResponse<{task_id: string}> = await makeRequest<{task_id: string}>({
            method: "POST",
            url: backupApi
                ? "https://back3-corp.skillometer.ru/api/v2/teacher/speciality/education_plan/"
                : "https://skillometer.ru/api/v2/web/user/education_plan/",
            data: {
                speciality_tag: id,
                language
            },
            headers: {
                "authorization": backupApi ? `Token ${back3Token}` : `Token ${skillometerToken}`,
                "organization-subdomain": backupApi ? '202' : null
            }
        });
        return response.status === 201 ? response.data : {task_id: ''}
    };
    const generationResultRequest = async (task_id: string) => {
        // @ts-ignore
        const response: ApiResponse<{complete: boolean, result?: string}> = await makeRequest<{complete: boolean, result: string}>({
            method: "GET",
            url: backupApi
                ? `https://back3-corp.skillometer.ru/api/celery-progress/${task_id}/`
                : `https://skillometer.ru/api/celery-progress/${task_id}/`,
            headers: {
                // "authorization": backupApi ? `Token ${process.env.BACK3_TOKEN}` : `Token ${process.env.SKILLOMETER_TOKEN}`,
                "organization-subdomain": backupApi ? '202' : null
            }
        });
        return response.status === 200 ? response.data : {complete: false, result: ''}
    };
    const generationHandler = async () => {
        setActiveSlide(3)
        setModalWarning(false);
        setLoading(true)
        const {index, id} = await matchIDRequest(skillHH?.name as string)
        const {task_id} = await initializeGenerationRequest(id, lang.selected)
        const interval = setInterval(async () => {
            const {complete, result} = await generationResultRequest(task_id)
            if (complete && result) {
                localStorage.setItem('generateData',JSON.stringify({hhSkill: skillHH, hhProfession: selectedProfession, index, id, task_id}))
                clearInterval(interval)
                setPlan(result)
                setTaskId(task_id)
                setLoading(false)
            }
        }, 5000)
    }
    const actionHandler = () => {
        if (plan) {
            if (activeSlide === 2) {
                setActiveSlide(3)
                return;
            }
            setModalInvite(true)
        } else {
            if (!skillHH?.name) return
            setModalWarning(true)
        }
    }
    // Модальные окна
    const [modalWarning, setModalWarning] = useState(false);
    const toggleModalWarning = () => {
        setModalWarning((prev) => !prev);
    };
    const [modalInvite, setModalInvite] = useState(false);
    const toggleModalInvite = () => {
        setModalInvite((prev) => !prev);
    };
    // localStorage
    useEffect(() => {
        const genDataStr = localStorage.getItem('generateData')
        if (genDataStr) {
            const genData: TypeGenerateData = JSON.parse(genDataStr)
            if (genData?.hhProfession) setSelectedProfession(genData.hhProfession)
            if (genData?.hhSkill) setSkillHH(genData.hhSkill)
            if (genData?.id) setVerifyId(genData.id)
            if (genData?.task_id) {
                setTaskId(genData.task_id)
                generationResultRequest(genData.task_id).then( res => {
                    setPlan(res?.result || '')
                })
            }
        }
    }, [])
    // console.log("ganerate: ", {selectedProfession, skillHH, verifyId, taskId, isMobile})
    return (
        <div className={s.Generate}>
            <div className={s.blur_circle}></div>
            <div className={cn(s.slider,  {[s.second]: activeSlide === 2, [s.third]: activeSlide === 3})}>
                <div className={cn(s.slide,  {[s.active]: activeSlide === 1})}>
                    <div className={s.title}>Создание курса с помощью нейросети</div>
                    <div className={s.subtitle}>Выберите желаемую профессию</div>
                    <div className={s.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white">
                            <path d="m668-380 152-130 120 10-176 153 52 227-102-62-46-198Zm-94-292-42-98 46-110 92 217-96-9ZM294-287l126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM173-120l65-281L20-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-340Z"/>
                        </svg>
                        <div>AI</div>
                    </div>
                    <Step_1
                        selectedProfession={selectedProfession}
                        setSelectedProfession={setSelectedProfessionHandler}
                        isMobile={isMobile}
                        backupApi={backupApi}
                        setBackupApi={() => setBackupApi(!backupApi)}
                        back3Token={back3Token}
                        skillometerToken={skillometerToken}
                    />
                    <div className={s.nav}>
                        <div className={s.page}>01/03</div>
                        <div className={cn(s.btn, s.space_left, {[s.disabled]: !selectedProfession})} onClick={() => {setActiveSlide(2)}}>Далее</div>
                    </div>
                </div>
                <div className={cn(s.slide,  {[s.active]: activeSlide === 2})}>
                    <div className={s.title}>Создание курса с помощью нейросети</div>
                    <div className={s.subtitle}>Выберите навык из вакансий</div>
                    <div className={s.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white">
                            <path d="m668-380 152-130 120 10-176 153 52 227-102-62-46-198Zm-94-292-42-98 46-110 92 217-96-9ZM294-287l126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM173-120l65-281L20-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-340Z"/>
                        </svg>
                        <div>AI</div>
                    </div>
                    <Step_2
                        selectedProfession={selectedProfession}
                        skillHH={skillHH} setSkillHH={setSkillHH}
                        isMobile={isMobile}
                        backupApi={backupApi}
                        setBackupApi={() => setBackupApi(!backupApi)}
                        back3Token={back3Token}
                        skillometerToken={skillometerToken}
                    />
                    <div className={s.nav}>
                        <div className={s.page}>02/03</div>
                        <div className={cn(s.btn, s.back, s.space_left)} onClick={() => {setActiveSlide(1)}}>Назад</div>
                        <div className={cn(s.btn, {[s.disabled]: !skillHH})} onClick={actionHandler}>{plan ? "Далее" : "Сгенерировать план обучения"}</div>
                        <div className={cn(s.lang_changer, {[s.open]: lang.active})}>
                            {lang.active
                                ? <>{lang.list.map((el, i) => <div key={i} onClick={() => setLang((prev) => ({...prev, active: false, selected: el}))}>{el.toUpperCase()}</div>)}</>
                                : <>
                                    {getLangFlag(lang.selected, () => setLang((prev) => ({...prev, active: true})))}
                                    <div onClick={() => setLang((prev) => ({...prev, active: true}))}>{lang.selected.toUpperCase()}</div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={cn(s.slide,  {[s.active]: activeSlide === 3})}>
                    <div className={s.title}>Создание курса с помощью нейросети</div>
                    <div className={s.subtitle}>Структура курса - {skillHH?.name || ''}</div>
                    <div className={s.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white">
                            <path d="m668-380 152-130 120 10-176 153 52 227-102-62-46-198Zm-94-292-42-98 46-110 92 217-96-9ZM294-287l126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM173-120l65-281L20-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-340Z"/>
                        </svg>
                        <div>AI</div>
                    </div>
                    <Step_3 plan={plan} loading={loading}/>
                    <div className={s.nav}>
                        <div className={s.page}>03/03</div>
                        <div className={cn(s.subtitle, s.nav_text)} style={{marginBottom: 0}}>Ваш курс почти готов, осталось пройти регистрацию</div>
                        <div className={cn(s.btn, s.back, s.space_left)} onClick={() => {setActiveSlide(2)}}>Назад</div>
                        {!loading && <div className={cn(s.btn, {[s.disabled]: !skillHH})} onClick={actionHandler}>Создать курс!</div>}
                    </div>
                </div>
            </div>
            <Modal isOpen={modalWarning} onClose={toggleModalWarning} >
                <div className={s.modal}>
                    <div>Вы уверены?<br/>У вас только одна попытка бесплатной генерации</div>
                    <Button text="Да" width="100%" cb={generationHandler}/>
                </div>
            </Modal>
            <Modal isOpen={modalInvite} onClose={toggleModalInvite}>
                <div className={s.modal}>
                    <div>Приглашаем вас пройти регистрацию на платформе Skillometer!</div>
                    <Button text="Регистрация" width="100%" link={getLink()}/>
                </div>
            </Modal>
        </div>
    )
}

export default Generate