"use client"
import React, {ReactNode, useEffect, useRef, useState} from "react";
import s from "./Carousel.module.scss";

type CarouselProps = {
    children: ReactNode;
    speed?: number;
    gap?: string
};

const Carousel: React.FC<CarouselProps> = ({ children, speed = 15, gap= '10px' }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let animationFrameId: number;
        let position = 0;

        const step = () => {
            if (contentRef.current) {
                position -= speed / 60;
                if (Math.abs(position) >= contentRef.current.scrollWidth / 2) {
                    position = 0;
                }
                contentRef.current.style.transform = `translateX(${position}px)`;
            }
            animationFrameId = requestAnimationFrame(step);
        };

        animationFrameId = requestAnimationFrame(step);
        return () => cancelAnimationFrame(animationFrameId);
    }, [speed]);

    return (
        <div className={s.carousel} ref={containerRef}>
            <div className={s.content} ref={contentRef}>
                {children}
                {children}
                {children}
                {children} {/* Дублируем контент для бесшовного эффекта */}
            </div>
        </div>
    );
};

export default Carousel;
