import {FC, useEffect, useMemo, useState} from "react";
import cn from "classnames";
import {ApiResponse, makeRequest} from "../../../api/api";
import {IProfession} from "../Step_1/Step_1";
import {getRandomArray} from "../../../utils/helpers/getRandomArray";
import s from "./Step_2.module.scss"

interface IStep_2 {
    selectedProfession: IProfession | null
    skillHH: ISkillHH | null
    setSkillHH: (skill: ISkillHH) => void
    isMobile: boolean
    backupApi: boolean
    setBackupApi: () => void
    skillometerToken: string
    back3Token: string
}

interface IVacancy {
    id: string
    name: string
    employer: {
        name: string
    }
    salary?: {
        to?: number,
        from?: number,
        gross?: boolean
        currency?: string
    }
    skills?: ISkillHH[]
}

export interface ISkillHH {
    id: number
    name: string
}

const Step_2:FC<IStep_2> = ({selectedProfession, skillHH, setSkillHH, isMobile, backupApi, setBackupApi, back3Token, skillometerToken}) => {

    const [allVacancies, setAllVacancies] = useState<IVacancy[]>([])

    const getVacancies = useMemo(() => {
        if (allVacancies.length === 0) return [] as IVacancy[]
        return getRandomArray<IVacancy>(allVacancies, isMobile ? 1 : 3)
    }, [allVacancies])

    const getCurrency = (currency: string) => {
        if (currency === "BYR")return 'Br'
        if (currency === "EUR")return '€'
        if (currency === "USD")return '$'
        if (currency === "KZT")return '₸'
        return '₽'
    }

    useEffect(() => {
        const fetchProfessions = async () => {
            setAllVacancies([])
            try {
                if (!selectedProfession?.name) return
                // @ts-ignore
                const response: ApiResponse<{ vacancies: IVacancy[] }> = await makeRequest<{ vacancies: IVacancy[] }>({
                    method: "GET",
                    url: backupApi
                        ? "https://back3-corp.skillometer.ru/api/v2/teacher/vacancy/hh/vacancies/"
                        : "https://skillometer.ru/api/v2/web/student/vacancy/hh/vacancies/",
                    params: {
                        text: selectedProfession?.name
                    },
                    headers: {
                        "authorization": backupApi ? `Token ${back3Token}` : `Token ${skillometerToken}`,
                        "organization-subdomain": backupApi ? '202' : null
                    }
                }).catch((e) => {
                    setBackupApi()
                });
                setAllVacancies(response.data.vacancies || [])
            } catch (e) {
                setAllVacancies([])
            }
        };
        fetchProfessions();
    }, [selectedProfession]);
    // console.log('allVacancies: ', allVacancies)
    return (
        <div className={s.Step_2}>
            <div className={s.vac_list}>
                {getVacancies.length
                    ? getVacancies.map(el => <div className={s.item} key={el.id}>
                        <div className={s.head}>
                            <div className={s.title}>{el.name}</div>
                            <div className={s.icon}>
                                <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M16.5715 0.144328C12.3318 0.864332 8.8354 2.67758 5.84148 5.70885C2.01457 9.58342 0 14.5185 0 20.018C0 25.5158 2.01197 30.4459 5.83953 34.3272C8.5492 37.0748 11.7101 38.8232 15.5729 39.7107C16.6388 39.9557 17.2393 40 19.4959 40C21.7525 40 22.3529 39.9557 23.4188 39.7107C29.1011 38.405 33.6842 34.9526 36.511 29.8488C39.8297 23.8563 39.8297 16.1797 36.511 10.1872C35.5481 8.44885 34.5761 7.15274 33.1522 5.70885C30.4583 2.97714 27.2584 1.20133 23.4852 0.343987C21.9418 -0.00678733 18.1126 -0.117382 16.5715 0.144328ZM11.0477 14.2676C11.0477 16.1064 11.0844 17.6109 11.1294 17.6109C11.1744 17.6109 11.4348 17.4028 11.7084 17.1485C13.5949 15.3951 16.7372 15.5997 18.0428 17.561C18.6362 18.4527 18.716 19.2223 18.716 24.058V28.5767H17.1643H15.6126L15.5721 24.1241C15.5281 19.2763 15.5251 19.2565 14.755 18.7374C13.9733 18.2105 12.6311 18.4223 11.9215 19.1846C11.1679 19.9941 11.1251 20.2673 11.0727 24.5983L11.0245 28.5767H9.47642H7.92832V19.7505V10.9243H9.48799H11.0477V14.2676ZM23.395 14.2676C23.395 16.1064 23.4318 17.6109 23.4768 17.6109C23.5218 17.6109 23.7822 17.4028 24.0558 17.1485C24.7355 16.5168 25.4311 16.1742 26.3362 16.0255C27.8968 15.7691 29.5552 16.3522 30.298 17.4181C30.9857 18.4051 31.0634 19.0795 31.0634 24.058V28.5767H29.5103H27.9571L27.9181 24.1241C27.8831 20.137 27.8552 19.6337 27.6498 19.3116C26.6814 17.7922 24.2262 18.366 23.6425 20.2484C23.5414 20.5743 23.4656 22.141 23.4287 24.6651L23.3717 28.5767H21.8237H20.2757V19.8397C20.2757 15.0343 20.3147 11.0625 20.3624 11.0135C20.41 10.9645 21.1118 10.9243 21.9221 10.9243H23.395V14.2676Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className={s.skill_list}>
                            {el.skills?.map(skill => <div title={skill.name} className={cn(s.skill, {[s.active]: skill.id === skillHH?.id })} key={skill.id} onClick={() => setSkillHH(skill)}>
                                <div>{skill.name}</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                    <circle cx="11.8477" cy="11" r="11" fill="#3077FF"/>
                                    <path d="M11.8476 5.8667L11.8476 16.1334M6.71429 11L16.981 11" stroke="white" strokeLinecap="round"/>
                                </svg>
                            </div>)}
                        </div>
                        <div className={s.footer}>
                            <div className={s.salary}>{el.salary?.to || el.salary?.from}{el.salary ? getCurrency(el.salary?.currency || '') : ''}</div>
                            <div className={s.company}>{el.employer.name}</div>
                        </div>
                    </div>)
                    : <div className={cn(s.item, s.preload)}>
                        <div className={s.head}>
                            <div className={s.title}>{selectedProfession?.name || 'Ищем'}...</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Step_2