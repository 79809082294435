import { useState, useEffect, useRef } from "react";

type InViewHookReturn = [React.RefObject<HTMLDivElement | null>, boolean];

const useInView = (options: IntersectionObserverInit = {}): InViewHookReturn => {
    const [inView, setInView] = useState(false); // Состояние видимости элемента
    const ref = useRef<HTMLDivElement | null>(null); // Ссылка на отслеживаемый элемент

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting); // Обновляем состояние видимости
            },
            options
        );

        const currentElement = ref.current;
        if (currentElement) {
            observer.observe(currentElement); // Начинаем наблюдение за элементом
        }

        return () => {
            if (currentElement) observer.unobserve(currentElement); // Останавливаем наблюдение за элементом
            observer.disconnect(); // Удаляем наблюдателя
        };
    }, [options.root, options.rootMargin, options.threshold, options]);

    return [ref, inView];
};

export default useInView;