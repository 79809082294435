import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

// Создаем инстанс axios с базовой конфигурацией
const api: AxiosInstance = axios.create({
    baseURL: "http://localhost:3001", // Замените на ваш базовый URL
    timeout: 10000, // Максимальное время ожидания запроса в миллисекундах
    headers: {
        "Content-Type": "application/json",
    },
});

// Интерцептор для добавления токенов или обработки запросов
api.interceptors.request.use(
    (config) => {
        // Пример: Добавление токена авторизации
        // const token = localStorage.getItem("token");
        // if (token) {
        //     config.headers.Authorization = `Bearer ${token}`;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Интерцептор для обработки ошибок или кастомизации ответов
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Обработка ошибок
        console.error("API Error:", error.response?.data || error.message);
        return Promise.reject(error);
    }
);

// Типизация шаблона для запросов
export interface ApiResponse<T> {
    data: T; // Данные ответа
    status: number; // HTTP статус
    message?: string; // Сообщение об ошибке или успехе
}

// export interface ApiRequestConfig extends AxiosRequestConfig {
//
// }

// Шаблон функции запроса
export const makeRequest = async <T>(
    config: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
    try {
        const response: AxiosResponse<T> = await api.request<T>(config);
        return {
            data: response.data,
            status: response.status,
        };
    } catch (error: any) {
        // Обработка ошибок
        throw {
            status: error.response?.status || 500,
            message: error.response?.data?.message || error.message,
        };
    }
};

export default api;