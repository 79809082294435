export const links = {
    googlePlay: 'https://play.google.com/store/apps/details?id=ru.skillometer.web&pli=1',
    appStore: 'https://apps.apple.com/ru/app/skillometer/id6450397269',
    auth: 'https://web.skillometer.ru/student/auth',
    auth_edu: 'https://edu.skillometer.ru/backoffice/login',
    reg_edu: 'https://edu.skillometer.ru/backoffice/register',
    searchTalents: 'https://web.skillometer.ru/search-talants',
    generateCourse: 'https://web.skillometer.ru/generate-course',
    courseList: 'https://web.skillometer.ru/course-list',
    tg: 'https://t.me/skillometer',
    vk: 'https://vk.com/skillometer',
    youtube: 'https://www.youtube.com/@skillometer',
    legal: 'https://skillometer.ru/legal',
    offer: 'https://skillometer.ru/offer',
    form: 'https://forms.gle/Dg9fNscRhw5jEjwk6',
    docs: {
        edu: {
            stepByStepInstructions: 'https://docs.google.com/document/d/1IKa22Kq4MJKYao1a1zuUAxwMCzoWS-Z97auocQdrCsM/edit?usp=sharing',
            descriptionFunctionalCharacteristics: 'https://docs.google.com/document/d/1ZkM37OSk3VKtjjvFx9t-5BQJHwSkiAVy_VXVOTGSFcM/edit?usp=sharing',
            descriptionProcesses: 'https://docs.google.com/document/d/1Ni4J_SbvmqjPh0Y_vxkosogdAef8tPwm7rhNPqU3CEM/edit?usp=sharing',
            documentationOperating: 'https://docs.google.com/presentation/d/1hKDMr-ANJf27OQ8VJqX201zyjxk7yppVk4q4qPhtVHw/edit?usp=sharing',
            architecture: "https://docs.google.com/document/d/1PoxjyG7XaxG-IuN333fgHEhNU7oTu0O5XCMnjnjRnqY/edit?usp=sharing",
            tariffs: "https://docs.google.com/presentation/d/1OzUmvOpEZIg_s9ZwU-CaWIVOho2rPzSwpr_Jr4hbneg/edit?usp=sharing"
        },
        business: {
            stepByStepInstructions: 'https://docs.google.com/document/d/12ypWeoJRUPc0P5UWRbq_f_RWXDPtbcX8mIYm5YxOnXE/edit?tab=t.0',
            descriptionFunctionalCharacteristics: 'https://docs.google.com/document/d/1njNYqqKvDUPOhtH0hTNFyo-rbDMwv6F9N-Y3BPMzNT0/edit?usp=sharing',
            descriptionProcesses: 'https://docs.google.com/document/d/1AqG4_TIfzUT6zBsadW471mp6fGRriY_l343moDDiIKM/edit?usp=sharing',
            documentationOperating: 'https://docs.google.com/presentation/d/1LTlH44HcV0p6iUyCGcrSrVmlTVaKChQYIgO6qCGq2ms/edit?usp=sharing',
            architecture: "https://docs.google.com/document/d/1y0WfgE0c2VP92svzwhRooFSn1V86i-EjFPwNbX11ry0/edit?usp=sharing",
            tariffs: "https://docs.google.com/presentation/d/1TP5ogaeKt6lHnO89CDwNt_f9Q1Qt0DhBTq4A5W6jstw/edit?usp=sharing"
        },
    }
}