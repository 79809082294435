"use client"
import {FC, useEffect, useState} from "react";
import cn from "classnames";
import Button from "../shared/Button/Button";
import {links} from "../../utils/links";
import Link from "next/link";
import Image from "next/image";
import useInView from "../../utils/hooks/useInView";
import s from "./Header.module.scss"
// import Modal from "@c/shared/Modal/Modal";
// import ModalTryFree from "@c/modals/ModalTryFree/ModalTryFree";

interface IHeader {
    context: 'Для бизнеса' | 'Для учебных центров' | 'Для учеников'
}

const Header:FC<IHeader> = ({ context}) => {
    const toggleTheme = () => {
        const currentTheme = document.documentElement.getAttribute('data-theme');
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('theme', newTheme); // Сохранение в localStorage
    }

    const [sidebar, setSidebar] = useState<boolean>(false)

    const toggleSidebar = () => {
        setSidebar(!sidebar)
    }

    const getLink = () => {
        if (context === 'Для бизнеса') return links.form
        if (context === 'Для учебных центров') return links.auth_edu
        if (context === 'Для учеников') return links.auth
    }
    // отслеживание прокрутки
    const [ref, inView] = useInView({
        threshold: 0.5, // Элемент считается видимым, если 50% его площади попало в область просмотра
    });

    useEffect(() => {
        // Установка темы при загрузке страницы
        const savedTheme = localStorage.getItem('theme') || 'black';
        document.documentElement.setAttribute('data-theme', savedTheme);
    }, [])
    return (<>
        <div style={{position: "absolute", opacity: 0}} ref={ref}>...</div>
        <header className={s.Header}>
            <Image width={158} height={28} className={cn({[s.hidden]: !inView})} src={"/assets/icons/header_logo.svg"} alt="header_logo"/>
            <Link href="#start" className={cn(s.label, {[s.hidden]: inView})}>{context}</Link>
            <div className={s.navigation}>
                {/*<a className={s.navigation__item} href="#possibilities">Возможности</a>*/}
                {context === 'Для учеников'
                    ? <Link href="#possibilities" className={s.navigation__item}>Возможности</Link>
                    : <Link href="#functions" className={s.navigation__item}>Функции</Link>
                }
                {context === 'Для учеников'
                    ? <Link href="#ai" className={s.navigation__item}>AI</Link>
                    : <Link href="#cases" className={s.navigation__item}>Кейсы</Link>
                }
                {/*<div className={s.navigation__item} onClick={() => scrollToTarget('tariffs')}>Тарифы</div>*/}
                {/*<div className={s.navigation__item} onClick={() => scrollToTarget('faq')}>{context === 'Для учеников' ? "FAQ" : "Документация"}</div>*/}
                {context !== 'Для учеников' && <Link href="#faq" className={s.navigation__item}>Документация</Link>}
                <Link href="#contacts" className={s.navigation__item}>Контакты</Link>
            </div>
            <div className={s.controls}>
                <Image width={24} height={24} src={"/assets/icons/header_theme.svg"} alt="header_theme" onClick={toggleTheme}/>
                <Button theme={false} text={context === 'Для бизнеса' ? 'Получить пробный доступ' : 'Войти'} link={getLink()} class_={s.skip_btn + " " + s.auth}/>
                {context === 'Для учебных центров' && <Button theme={false} text={'Регистрация'} link={links.reg_edu} class_={s.skip_btn}/>}
                {/*<Button theme={false} text={'Регистрация'} cb={toggleModal} class_={s.skip_btn}/>*/}
                <Image width={18} height={14} className={s.menu_btn} src={"/assets/icons/header_menu.svg"} alt="header_menu" onClick={toggleSidebar}/>
            </div>
            <div className={cn(s.sidebar, {[s.show]: sidebar})}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={toggleSidebar}>
                    <path d="M8 8L15 15M8 8L1 1M8 8L1 15M8 8L15 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <Button width={'250px'} theme={true} text={context === 'Для бизнеса' ? 'Получить пробный доступ' : 'Войти'} link={getLink()}/>
                {context === 'Для учебных центров' && <Button theme={true} text={'Регистрация'} link={links.reg_edu}/>}
                {/*<Button theme={true} text={'Попробовать бесплатно'} cb={toggleModal}/>*/}
                {context === 'Для учеников'
                    ? <Link href="#possibilities" className={s.item}>Возможности</Link>
                    : <Link href="#functions" className={s.item}>Функции</Link>
                }
                {context === 'Для учеников'
                    ? <Link href="#ai" className={s.item}>AI</Link>
                    : <Link href="#cases" className={s.item}>Кейсы</Link>
                }
                {/*<div className={s.item} onClick={() => scrollToTarget('tariffs')}>Тарифы</div>*/}
                {context !== 'Для учеников' && <Link href="#faq" className={s.item} >Документация</Link>}
                <Link href="#contacts" className={s.item}>Контакты</Link>
            </div>
            {/*<Modal isOpen={isModalOpen} onClose={toggleModal}>*/}
            {/*    <ModalTryFree toggleModal={toggleModal}/>*/}
            {/*</Modal>*/}
        </header>
    </>
    )
}

export default Header