import {FC, useEffect, useMemo, useState} from "react";
import {ApiResponse, makeRequest} from "../../../api/api";
import {getRandomArray} from "../../../utils/helpers/getRandomArray";
import {getSearchArray} from "../../../utils/helpers/getSearchArray";
import cn from "classnames";
import s from "./Step_1.module.scss"

interface IStep_1 {
    selectedProfession: IProfession | null
    setSelectedProfession: (profession: IProfession | null) => void
    isMobile: boolean
    backupApi: boolean
    setBackupApi: () => void
    skillometerToken: string
    back3Token: string
}

export interface IProfession {
    name: string
    id: number
}

const Step_1:FC<IStep_1> = ({selectedProfession, setSelectedProfession, isMobile, backupApi, setBackupApi, back3Token, skillometerToken}) => {

    const [allProfs, setAllProfs] = useState<IProfession[]>([])

    const [search, setSearch] = useState('')

    const getProfs = useMemo(() => {
        if (allProfs.length === 0) return []//{id: 0, name: 'Ищем...'}
        if (search) {
            // setSelectedProfession(null)
            return getSearchArray<IProfession>(allProfs, isMobile ? 4 : 10, search, 'name')
        } else {
            // setSelectedProfession(null)
            return getRandomArray<IProfession>(allProfs, isMobile ? 4 : 10)
        }
    }, [allProfs, search, isMobile])

    useEffect(() => {
        setSelectedProfession(null)
    }, [allProfs, search, isMobile])

    useEffect(() => {
        const fetchProfessions = async () => {
            // @ts-ignore
            const response: ApiResponse<IProfession[]> = await makeRequest<IProfession[]>({
                method: "GET",
                url: backupApi
                    ? "https://back3-corp.skillometer.ru/api/v2/teacher/speciality/profs_list"
                    : "https://skillometer.ru/api/v2/web/student/profession/profs_list/",
                headers: {
                    "authorization": backupApi ? `Token ${back3Token}` : `Token ${skillometerToken}`,
                    "organization-subdomain": backupApi ? '202' : null
                }
            }).catch((e) => {
                setBackupApi()
            })
            setAllProfs(response?.data || [])
        };
        fetchProfessions();
    }, [backupApi]);

    // console.log("selectedProfession: ", selectedProfession)

    return (
        <div className={s.Step_1}>
            <input type="text" placeholder="Поиск профессий" value={search} onChange={(e) => setSearch(e.target.value)}/>
            <div className={s.prof_list}>
                {!!getProfs.length
                    ? getProfs.map((el) => <div title={el.name} className={cn(s.item, {[s.active]: selectedProfession?.id === el.id})} key={el.id} onClick={() => setSelectedProfession(el)}>
                        <div>{el.name}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                            <circle cx="11.8477" cy="11" r="11" fill="#3077FF"/>
                            <path d="M11.8476 5.8667L11.8476 16.1334M6.71429 11L16.981 11" stroke="white" strokeLinecap="round"/>
                        </svg>
                    </div>)
                    : <div className={cn(s.item, s.preload)}>Ищем...</div>
                }
            </div>
        </div>
    )
}

export default Step_1