"use client"
import React, { FC, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import styles from "./Modal.module.scss";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    className?: string;
    overlayClassName?: string;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children, className, overlayClassName }) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isOpen) {
                onClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={cn(styles.overlay, { [styles.active]: isOpen }, overlayClassName)} onClick={onClose}>
            <div
                className={cn(styles.modal, { [styles.active]: isOpen }, className)}
                onClick={(e) => e.stopPropagation()}
            >
                <button className={styles.closeButton} onClick={onClose}>
                    ✖
                </button>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default Modal;