// export const getRandomArray = <T>(array: T, count: number): T => {
//     if (count >= array.length) {
//         return [...array] as T;
//     }
//
//     const shuffled = [...array];
//     for (let i = shuffled.length - 1; i > 0; i--) {
//         const j = Math.floor(Math.random() * (i + 1));
//         [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
//     }
//
//     return shuffled.slice(0, count) as T;
// };
export const getRandomArray = <T>(array: T[], count: number): T[] => {
    if (count >= array.length) {
        return [...array]; // Здесь теперь TypeScript понимает, что array — массив
    }

    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled.slice(0, count);
};

