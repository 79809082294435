// import { useState, useEffect } from "react";
//
// function useMediaQuery(maxWidth: number): boolean {
//     const [matches, setMatches] = useState<boolean>(
//         window.matchMedia(`(max-width: ${maxWidth}px)`).matches
//     );
//
//     useEffect(() => {
//         const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
//         const handleChange = () => {
//             console.log('change')
//             setMatches(mediaQuery.matches);
//         }
//
//         mediaQuery.addEventListener("change", handleChange);
//         return () => mediaQuery.removeEventListener("change", handleChange);
//     }, [maxWidth]);
//
//     return matches;
// }
//
// export default useMediaQuery;
import { useState, useEffect } from "react";

function useMediaQuery(maxWidth: number): boolean {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        if (typeof window === "undefined") return; // SSR защита

        const mediaQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
        const handleChange = () => setMatches(mediaQuery.matches);

        handleChange(); // Устанавливаем текущее значение
        mediaQuery.addEventListener("change", handleChange);

        return () => mediaQuery.removeEventListener("change", handleChange);
    }, [maxWidth]);

    return matches;
}

export default useMediaQuery;